import React from 'react'

function Facts() {
  return (

    <section className=' w-full mx-auto max-w-7xl '>
      <div className="px-5  py-16 md:px-10 md:py-24 lg:py-20">
        <div className="mx-auto w-full flex  max-w-[898px] flex-col rounded-[36px] bg-black py-8 text-white">
          <div className="grid h-full w-full grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4 md:gap-0">
            <div className="flex flex-col items-center justify-center lg:border-r lg:border-solid lg:border-r-[#c3c0c04d]">
              <h4 className="mb-4 text-2xl font-bold md:text-3xl">4+</h4>
              <p className="text-sm">Years In Business</p>
            </div>
            <div className="flex flex-col items-center justify-center lg:border-r lg:border-solid lg:border-r-[#c3c0c04d]">
              <h4 className="mb-4 text-2xl font-bold md:text-3xl">+2,000</h4>
              <p className="text-sm">Sold</p>
            </div>
            <div className="flex flex-col items-center justify-center lg:border-r lg:border-solid lg:border-r-[#c3c0c04d]">
              <h4 className="mb-4 text-2xl font-bold md:text-3xl">+2,000</h4>
              <p className="text-sm">Loyal Customers</p>
            </div>
            <div className="flex flex-col items-center justify-center lg:border-r lg:border-solid lg:border-r-[#c3c0c04d]">
              <h4 className="mb-4 text-2xl font-bold md:text-3xl">24/7</h4>
              <p className="text-sm">Support Available</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Facts